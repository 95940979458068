.c-container {
  max-width: 1350px;
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.c-container-log-out{
  max-width: 1200px;
  margin: 0 auto;
  /* overflow: auto; */
  padding: 0 40px; 
}
.c-card {
  background-color: #fff;
  color: #333;
  border-radius: 10px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.3);
  padding: 20px;
  margin: 10px;
}

.c-btn {
  display: inline-block;
  padding: 10px 30px;
  cursor: pointer;
  background: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: none;
}

.c-btn-outline {
  background-color: transparent;
  border: 1px #fff solid;
  outline: none;
}

.c-btn:hover {
  transform: scale(0.95);
}

.c-btn:disabled {
  opacity: 0.5;
}

/******************* Background & Colored Buttons *******************/
.c-bg-primary,
.c-btn-primary {
  background-color: var(--primary-color);
  color: #fff;
}

.c-bg-secondary,
.c-btn-secondary {
  background-color: var(--secondary-color);
  color: #fff;
}

.c-bg-dark,
.c-btn-dark {
  background-color: var(--dark-color);
  color: #fff;
}

.c-bg-light,
.c-btn-light {
  background-color: var(--light-color);
  color: #333;
}

.c-bg-success,
.c-btn-success {
  background-color: var(--success-color);
  color: #fff;
}

.c-bg-primary a,
.c-btn-primary a,
.c-bg-secondary a,
.c-btn-secondary a,
.c-bg-dark a,
.c-btn-dark a {
  color: #fff;
}

/******************* Text Colors *******************/
.c-text-primary {
  color: var(--primary-color);
}

.c-text-secondary {
  color: var(--secondary-color);
}

.c-text-dark {
  color: var(--dark-color);
}

.c-text-light {
  color: var(--light-color);
}

/******************* Text Sizes *******************/
.c-lead {
  font-size: 20px;
}

.c-sm {
  font-size: 1rem;
}

.c-md {
  font-size: 2rem;
}

.c-lg {
  font-size: 3rem;
}

.c-xl {
  font-size: 4rem;
}

.c-text-center {
  text-align: center;
}

/******************* Text Area *******************/
.c-text-area-1 {
  border: none;
  outline: none;
  height: 300px;
  width: 400px;
  background-color: #fff;
}

/******************* Flex & Grid *******************/
.c-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.c-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.c-grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

/******************* Margin *******************/
.c-my-1 {
  margin: 1rem 0;
}

.c-my-2 {
  margin: 1.5rem 0;
}

.c-my-3 {
  margin: 2rem 0;
}

.c-my-4 {
  margin: 3rem 0;
}

.c-my-5 {
  margin: 4rem 0;
}

.c-m-1 {
  margin: 1rem;
}

.c-m-2 {
  margin: 1.5rem;
}

.c-m-3 {
  margin: 2rem;
}

.c-m-4 {
  margin: 3rem;
}

.c-m-5 {
  margin: 4rem;
}

/******************* Padding *******************/
.c-py-1 {
  padding: 1rem 0;
}

.c-py-2 {
  padding: 1.5rem 0;
}

.c-py-3 {
  padding: 2rem 0;
}

.c-py-4 {
  padding: 3rem 0;
}

.c-py-5 {
  padding: 4rem 0;
}

.c-p-1 {
  padding: 1rem;
}

.c-p-2 {
  padding: 1.5rem;
}

.c-p-3 {
  padding: 2rem;
}

.c-p-4 {
  padding: 3rem;
}

.c-p-5 {
  padding: 4rem;
}

.header-container {
  margin: 0 auto;
  overflow: auto;
  padding: 0 40px;
}

.header_nav li {
  margin-bottom: 0;
}