.container-style{
    margin-bottom: 20px;
}

.btnStyle {
    background-color: #25a9da !important;
}

.dialogContainer {
    margin: 0 !important;
    width: 100% !important;
    padding: 1rem;
}